import {
  COMPANY_ATTRIBUTE_ID,
  ENTERPRISE_ATTRIBUTE_ID,
  MSTR_PROJECT_ID,
  MSTR_QUERY_PARAM,
  X_MSTR_AUTHTOKEN,
} from "../config/mstrConfig";
import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { AttributeElement } from "../model/dossierDefinitionModel";
import { DossierDefinitionModel } from "../model/dossierDefinitionModel";
import {
  getUserMstrId as getUserIdFromSession,
  setUserMstrId,
  getAttributeElements as getAttributeElementsFromSession,
  setAttributeElements,
  clearAttributeElements,
  clearUserMstrId,
} from "../utility/sessionStorageHelper";
import { MstrApiService } from "./apiService";
import { LogService } from "./logService";

export interface MstrInitializeResponse {
  token?: string;
  userInitialized?: boolean;
  enterpriseAttributesInitialized?: boolean;
  companyAttributesInitialized?: boolean;
}

const mstrUrl = currentEnvironment().customConfiguration.mstrUrl;

const attributeElements = (attributeId: string) =>
  getAttributeElementsFromSession(attributeId);

const dossierDefinitions = async (dossierId: string) => {
  try {
    const headers = {
      "X-MSTR-ProjectID": MSTR_PROJECT_ID,
      "content-type": "application/json",
    };
    const response = await MstrApiService.get(
      `${mstrUrl}/api/dossiers/${dossierId}/definition`,
      headers
    );

    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const definition = await response.json();
    return definition as DossierDefinitionModel;
  } catch (error) {
    LogService.log({
      message: `Error while fetching dossier definition from microstrategy for dossier ${dossierId}, error - ${error}`,
      type: LogType.Error,
      method: "getDossierDefinition",
      file: "mstrRestService.ts",
    });
    return null;
  }
};

const logout = async () => {
  return await MstrApiService.post(`${mstrUrl}/api/auth/logout`);
};

const createInstance = async (
  dossierId: string,
  payload: any,
  authToken: string
) => {
  try {
    const headers = {
      "X-MSTR-AuthToken": authToken,
      accept: "application/json",
      "X-MSTR-ProjectID": MSTR_PROJECT_ID,
      "content-type": "application/json",
    };
    const response = await MstrApiService.post(
      `${mstrUrl}/api/dossiers/${dossierId}/instances`,
      payload,
      headers
    );

    if (!response.ok || response.status !== 201) {
      throw new Error(JSON.stringify(response));
    }

    const instanceId = (await response.json()).mid;
    return instanceId;
  } catch (error) {
    LogService.log({
      message: `Error in creating instance for dossier ${dossierId}- ${error}`,
      type: LogType.Error,
      method: "createInstance",
      file: "mstrRestService.ts",
    });
    return null;
  }
};

const initAttributeElementsFromAPI = async (attributeId: string) => {
  try {
    const headers = {
      "X-MSTR-ProjectID": MSTR_PROJECT_ID,
      "content-type": "application/json",
    };
    const response = await MstrApiService.get(
      `${mstrUrl}/api/attributes/${attributeId}/elements`,
      headers
    );

    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const elements = (await response.json()) as AttributeElement[];
    setAttributeElements(elements, attributeId);
    return true;
  } catch (error) {
    LogService.log({
      message: `Error while fetching attribute elements for from microstrategy for ${attributeId}, error - ${error}`,
      type: LogType.Error,
      method: "getAttributeElements",
      file: "mstrRestService.ts",
    });
    return false;
  }
};

async function initAttributeElements(attributeId: string) {
  const attributeElements = getAttributeElementsFromSession(attributeId);
  if (!attributeElements) {
    const response = await initAttributeElementsFromAPI(attributeId);
    return response;
  }
  return true;
}

const initUserIdFromAPI = async () => {
  try {
    const response = await MstrApiService.get(
      `${mstrUrl}/api/sessions/userInfo`
    );

    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }

    const userInfo = await response.json();
    setUserMstrId(userInfo.id);
    return true;
  } catch (error) {
    LogService.log({
      message: `Error while fetching userinfo from microstrategy- ${error}`,
      type: LogType.Error,
      method: "getUserInfo",
      file: "mstrRestService.ts",
    });
    return false;
  }
};

async function initUserId() {
  const id = getUserIdFromSession();
  if (!id) {
    const response = await initUserIdFromAPI();
    return response;
  }
  return true;
}

const initTokenFromApi = async () => {
  try {
    const response = await MstrApiService.get(`${mstrUrl}/api/auth/token`);
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const token = response.headers.get(X_MSTR_AUTHTOKEN);
    return token;
  } catch (error) {
    LogService.log({
      message: `Error while fetching token from microstrategy - ${error}`,
      type: LogType.Error,
      method: "getToken",
      file: "mstrRestService.ts",
    });
    return null;
  }
};

const invokeMstrLogin = () => {
  const mstr_url_param = `${MSTR_QUERY_PARAM}=true`;
  let location = `${window.location.href}`;
  if (!location.includes(mstr_url_param)) {
    location = location.includes("?")
      ? `${location}&${mstr_url_param}`
      : `${location}?${mstr_url_param}`;
  }

  const encodedLocation = encodeURIComponent(location);
  const url = `${mstrUrl}/auth/login-dialog.jsp?loginMode=4194304&callback-origin=${encodedLocation}`;

  window.open(url, "_self");
};

async function initialize(
  mstrInvoked: boolean
): Promise<MstrInitializeResponse> {
  if (!mstrInvoked) {
    invokeMstrLogin();
    return {};
  }
  try {
    const token = await initTokenFromApi();
    if (token) {
      const [
        userInitialized,
        enterpriseAttributesInitialized,
        companyAttributesInitialized,
      ] = await Promise.all([
        initUserId(),
        initAttributeElements(ENTERPRISE_ATTRIBUTE_ID),
        initAttributeElements(COMPANY_ATTRIBUTE_ID),
      ]);
      return {
        token: token,
        userInitialized,
        enterpriseAttributesInitialized,
        companyAttributesInitialized,
      };
    }
  } catch (e) {
    LogService.log({
      message: `Error while initializing microstrategy for , error - ${e}`,
      type: LogType.Error,
      method: "initMstrSettings",
      file: "mstrRestService.ts",
    });
  }
  return {};
}

async function clear() {
  clearAttributeElements(ENTERPRISE_ATTRIBUTE_ID);
  clearAttributeElements(COMPANY_ATTRIBUTE_ID);
  clearUserMstrId();
}

const MstrRestService = {
  initialize,
  logout,
  createInstance,
  dossierDefinitions,
  clear,
  attributeElements,
};
export default MstrRestService;
