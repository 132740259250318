import { LogType, MstrMessageType } from "../enum";
import { MstrMessage, PendoMstrMessage } from "../model/mstrMessageModel";
import { PendoModel } from "../model/pendoModel";
import {
  setPendoModel,
  getPendoModel,
  clearPendoModel,
} from "../utility/sessionStorageHelper";
import { LogService } from "./logService";
import MstrMessagingService from "./mstrMessagingService";

function save(pendoModel: PendoModel) {
  setPendoModel(pendoModel);
}

function get(): PendoModel | undefined {
  return getPendoModel();
}

function clear() {
  clearPendoModel();
}

const postMessage = (id: string) => {
  try {
    const pendoModel = get();
    if (!pendoModel) return;
    const mstrMessage: MstrMessage = {
      type: MstrMessageType.Pendo,
      message: pendoModel as PendoMstrMessage,
    };
    if (pendoModel) MstrMessagingService.postToDossier(id, mstrMessage);
  } catch (error) {
    LogService.log({
      message: `Error while sending pendo initialization message to the dossier : ${error}`,
      type: LogType.Error,
      method: "postPendoMessageToDossier",
      file: "pendoService.ts",
    });
  }
};

const initialize = (pendoModel: PendoModel) => {
  const pendo = (window as any).pendo;
  pendo.initialize({
    visitor: pendoModel.visitor,
    account: pendoModel.account,
  });
};

const PendoService = {
  save,
  get,
  clear,
  postMessage,
  initialize,
};

export default PendoService;
