export const X_MSTR_AUTHTOKEN = "x-mstr-authtoken";
export const MSTR_PROJECT_ID = "07776E04B44C7650DA71388C93332C07";
export const MSTR_ACCOUNTING_REPORT_ID = "261C29ABCC456F7E55336BAA30F65AFA";
export const MSTR_ACCOUNTING_REPORT_PAGE_KEY = "K53";
export const MSTR_ACCOUNTING_DASHBOARD_ID = "8FCF8765B6475D8295F458991C343246";
export const MSTR_IDLE_TIMEOUT = 2 * 60 * 60 * 1000;
export const MSTR_CONTAINER_ID = "mstr-container";
export const ENTERPRISE_ATTRIBUTE_ID = "7359B6504F3FDA72E461B0885D3AE7A8";
export const COMPANY_ATTRIBUTE_ID = "1833E080468B2524D3F42A8B746058D7";
export const MSTR_QUERY_PARAM = "mstr_invoked";
