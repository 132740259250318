import { BridgeUser } from "../model/bridgeUserModel";
import {
  AttributeElement,
  DossierGlobalFilterKey,
} from "../model/dossierDefinitionModel";
import { PendoModel } from "../model/pendoModel";
import { UserAccount } from "../model/userAccountModel";
import { NativePagesDossier } from "../model/userNativeReportsModel";
import { UserPreference } from "../model/userPreferenceModel";
import { UserReport } from "../model/userReportsModel";

const ACCOUNTING_DASHBOARD_INSTANCE_ID = "accounting_dashboard_instance_id";
const ACCOUNTING_REPORT_INSTANCE_ID = "accounting_report_instance_id";
const PENDO_MODEL = "pendo_model";
const USER_REPORTS = "user_reports";
const USER_ACCOUNT = "user_account";
const USER_PREFERENCE = "user_preference";
const USER_MSTR_ID = "user_mstr_id";
const BRIDGE_USER = "bridge_user";
const USER_NATIVE_REPORTS = "user_native_report";
const DOSSIER_GLOBAL_FILTER_KEYS = "native_report_global_filter_keys";


export const getAccountingDashboardInstanceId = () =>
  sessionStorage.getItem(ACCOUNTING_DASHBOARD_INSTANCE_ID);

export const getAccountingReportInstanceId = () =>
  sessionStorage.getItem(ACCOUNTING_REPORT_INSTANCE_ID);

export const setAccountingDashboardInstanceId = (id: string) =>
  sessionStorage.setItem(ACCOUNTING_DASHBOARD_INSTANCE_ID, id);

export const setAccountingReportInstanceId = (id: string) =>
  sessionStorage.setItem(ACCOUNTING_REPORT_INSTANCE_ID, id);

export const clearAccountingInstanceIds = () => {
  sessionStorage.removeItem(ACCOUNTING_DASHBOARD_INSTANCE_ID);
  sessionStorage.removeItem(ACCOUNTING_REPORT_INSTANCE_ID);
};

export const setPendoModel = (pendoModel: PendoModel) =>
  sessionStorage.setItem(PENDO_MODEL, JSON.stringify(pendoModel));

export function getPendoModel(): PendoModel | undefined {
  const pendoModel = sessionStorage.getItem(PENDO_MODEL);
  if (pendoModel) {
    return JSON.parse(pendoModel) as PendoModel;
  }
  return undefined;
}

export function clearPendoModel() {
  sessionStorage.removeItem(PENDO_MODEL);
}

export function setUserReports(userReports: UserReport[]) {
  sessionStorage.setItem(USER_REPORTS, JSON.stringify(userReports));
}

export function getUserReports(): UserReport[] | undefined {
  try {
    const userReportsFromSessionStorage = sessionStorage.getItem(USER_REPORTS);
    if (userReportsFromSessionStorage) {
      return JSON.parse(userReportsFromSessionStorage) as UserReport[];
    }
  } catch (e) {
    console.log(e);
  }

  return undefined;
}

export function clearUserReports() {
  sessionStorage.removeItem(USER_REPORTS);
}

export function setUserAccount(userAccount: UserAccount) {
  sessionStorage.setItem(USER_ACCOUNT, JSON.stringify(userAccount));
}

export function getUserAccount(): UserAccount | undefined {
  const userAccountsFromSessionStorage = sessionStorage.getItem(USER_ACCOUNT);
  if (userAccountsFromSessionStorage) {
    return JSON.parse(userAccountsFromSessionStorage) as UserAccount;
  }
  return undefined;
}

export function clearUserAccount() {
  sessionStorage.removeItem(USER_ACCOUNT);
}

export const setUserMstrId = (id: string) =>
  sessionStorage.setItem(USER_MSTR_ID, id);

export const getUserMstrId = () => sessionStorage.getItem(USER_MSTR_ID);

export const clearUserMstrId = () => {
  sessionStorage.removeItem(USER_MSTR_ID);
};

export const setBridgeUser = (model: BridgeUser) =>
  sessionStorage.setItem(BRIDGE_USER, JSON.stringify(model));

export const getBridgeUser = () => {
  const model = sessionStorage.getItem(BRIDGE_USER);
  if (model) {
    return JSON.parse(model) as BridgeUser;
  }
  return undefined;
};

export const clearBridgeUser = () => {
  sessionStorage.removeItem(BRIDGE_USER);
};

export function setUserNativeReports(userNativeReports: NativePagesDossier[]) {
  sessionStorage.setItem(
    USER_NATIVE_REPORTS,
    JSON.stringify(userNativeReports)
  );
}

export function getUserNativeReports(): NativePagesDossier[] {
  try {
    const userReportsFromSessionStorage =
      sessionStorage.getItem(USER_NATIVE_REPORTS);
    if (userReportsFromSessionStorage) {
      return JSON.parse(userReportsFromSessionStorage) as NativePagesDossier[];
    }
  } catch (e) {
    console.log(e);
  }
  return [];
}

export function clearUserNativeReports() {
  sessionStorage.removeItem(USER_NATIVE_REPORTS);
}

export function setNativeDossierGlobalFilterKeys(
  dossierGlobalFilterKeys: DossierGlobalFilterKey[]
) {
  sessionStorage.setItem(
    DOSSIER_GLOBAL_FILTER_KEYS,
    JSON.stringify(dossierGlobalFilterKeys)
  );
}

export function getNativeDossierGlobalFilterKeys(): DossierGlobalFilterKey[] {
  try {
    const dossierGlobalFilterKeys = sessionStorage.getItem(
      DOSSIER_GLOBAL_FILTER_KEYS
    );
    if (dossierGlobalFilterKeys) {
      return JSON.parse(dossierGlobalFilterKeys) as DossierGlobalFilterKey[];
    }
  } catch (e) {
    console.log(e);
  }
  return [];
}

export function clearNativeDossierGlobalFilterKeys() {
  sessionStorage.removeItem(DOSSIER_GLOBAL_FILTER_KEYS);
}

export function setUserPreference(userPreference: UserPreference) {
  sessionStorage.setItem(USER_PREFERENCE, JSON.stringify(userPreference));
}

export function getUserPreference(): UserPreference | undefined {
  try {
    const userPreference = sessionStorage.getItem(USER_PREFERENCE);
    if (userPreference) {
      return JSON.parse(userPreference) as UserPreference;
    }
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function clearUserPreference() {
  sessionStorage.removeItem(USER_PREFERENCE);
}

export function getAttributeElements(attributeId: string) {
  try {
    const attributeElements = sessionStorage.getItem(attributeId);
    if (attributeElements) {
      return JSON.parse(attributeElements) as AttributeElement[];
    }
  } catch (e) {
    console.log(e);
  }
  return undefined;
}

export function setAttributeElements(
  attributeElements: AttributeElement[],
  attributeId: string
) {
  sessionStorage.setItem(attributeId, JSON.stringify(attributeElements));
}

export function clearAttributeElements(attributeId: string) {
  sessionStorage.removeItem(attributeId);
}
