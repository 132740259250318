import {
  MSTR_ACCOUNTING_DASHBOARD_ID,
  MSTR_ACCOUNTING_REPORT_ID,
  MSTR_PROJECT_ID,
} from "../config/mstrConfig";
import { MstrMessageType } from "../enum";
import {
  AccountingSummaryMstrMessage,
  MstrMessage,
} from "../model/mstrMessageModel";
import {
  clearAccountingInstanceIds,
  getAccountingDashboardInstanceId,
  getAccountingReportInstanceId,
  getUserMstrId,
  setAccountingDashboardInstanceId,
  setAccountingReportInstanceId,
} from "../utility/sessionStorageHelper";
import MstrMessagingService from "./mstrMessagingService";

const postToggleFilterSummaryMessageToDossier = (
  id: string,
  isReport: boolean
) => {
  const userId = getUserMstrId();
  if (!userId) return;
  const accountingSummaryMstrMessage: AccountingSummaryMstrMessage = {
    userId: userId,
    projectId: MSTR_PROJECT_ID,
    accountingToBeSimulatedId: isReport
      ? MSTR_ACCOUNTING_REPORT_ID
      : MSTR_ACCOUNTING_DASHBOARD_ID,
    accountingToBeUpdatedId: isReport
      ? MSTR_ACCOUNTING_DASHBOARD_ID
      : MSTR_ACCOUNTING_REPORT_ID,
  };
  const mstrMessage: MstrMessage = {
    type: MstrMessageType.AccountingSummaryToggle,
    message: accountingSummaryMstrMessage,
  };
  MstrMessagingService.postToDossier(id, mstrMessage);
};

function setId(key: string, id: string) {
  if (key === MSTR_ACCOUNTING_DASHBOARD_ID)
    setAccountingDashboardInstanceId(id);
  else if (key === MSTR_ACCOUNTING_REPORT_ID) setAccountingReportInstanceId(id);
}

function getId(id: string) {
  if (id === MSTR_ACCOUNTING_DASHBOARD_ID)
    return getAccountingDashboardInstanceId();
  else if (id === MSTR_ACCOUNTING_REPORT_ID)
    return getAccountingReportInstanceId();
}

function clear() {
  clearAccountingInstanceIds();
}

const AccountingService = {
  setId,
  getId,
  clear,
  postToggleFilterSummaryMessageToDossier,
};

export default AccountingService;
